<template>
  <b-row>
    <b-col cols="12" v-if="can('read/pengelolaan-anggaran/summary')">
        <b-card title="Pengelolaan Anggaran Summary">
          <b-row class="pb-2">
            <b-col lg="4" md="4" xs="12" class="mb-1">
              <!-- <b-form-group
                label="Periode"
                label-cols="3"
                label-align="left"
                label-size="md"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0"
              > -->
                Periode
                <DatePicker
                  v-model="dateRange"
                  range
                  format="DD-MM-YYYY"
                  placeholder="Select Date Range"
                  :unlink-panels="true"
                  style="width: 100%;"
                  @change="handleSearch"
                />
              <!-- </b-form-group> -->
            </b-col>
            <b-col lg="8" md="8" xs="12" class="mb-1"></b-col>
            <b-col lg="2" md="2" xs="12" class="mb-1">
              <!-- <b-input-group size="8"> -->
                Type Anggaran
                <v-select
                  id="type"
                  v-model="selectedType"
                  label="text"
                  placeholder="Cari Type Anggaran"
                  :options="selectedTypeOptions"
                  class="form-select w-100"
                  @change="handleSearch"
                />
              <!-- </b-input-group> -->
            </b-col>
            <br>
            <b-col lg="5" md="5" xs="12" class="mb-1">
              <!-- <b-input-group size="8"> -->
                Sub Direktorat
                <v-select
                  id="subdir"
                  v-model="selectedSubdir"
                  label="text"
                  placeholder="Cari Sub Direktorat"
                  :options="selectedSubdirOptions"
                  class="form-select w-100"
                  v-on:change="handleSearch"
                />
              <!-- </b-input-group> -->
            </b-col>
            <b-col lg="5" md="5" xs="12" class="mb-1">
              <!-- <b-input-group size="8"> -->
                Regional
                <v-select
                  id="regional"
                  v-model="selectedRegional"
                  label="text"
                  placeholder="Cari Regional"
                  :options="selectedRegionalOptions"
                  class="form-select w-100"
                  v-on:change="handleSearch"
                />
              <!-- </b-input-group> -->
            </b-col>
            
            <b-col lg="12" md="12" xs="12">
              <b-form @submit.prevent="handleSearch">
                Pencarian
                <b-input-group size="8">
                  <b-form-input
                    id="filterInput"
                    v-model="search"
                    type="search"
                    :placeholder="'Cari disini...'"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!search" @click="search = ''">
                      Hapus
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
          <b-table
            ref="tableFe"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            :sort-by.sync="sortBy"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <div>Loading data, please wait...</div>
              </div>
            </template>
            <template #empty>
              <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #emptyfiltered>
              <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #cell(no)="data">
              {{ (currentPage - 1) * perPage + (data.index + 1) }}
            </template>
            <template #cell(anggaran)="data">
              <span class="text-capitalize d-block" style="text-align: end;">{{
                data.item.anggaran | currency
              }}</span>
            </template>
            <template #cell(realisasi)="data">
              <span class="text-capitalize d-block" style="text-align: end;">{{
                data.item.realisasi | currency
              }}</span>
            </template>
            <template #cell(sisa)="data">
              <span class="text-capitalize d-block" style="text-align: end;">{{
                data.item.sisa | currency
              }}</span>
            </template>
            <template #cell(name_subdirektorat)="data">
              {{ data.item.name_subdirektorat || "-" }}
            </template>
            <template #cell(name_regional)="data">
              {{ data.item.name_regional || "-" }}
            </template>
            <template #cell(type)="data">
              <span class="text-capitalize">{{
                data.item.type
              }}</span>
            </template>
            <!-- A virtual column -->
            <!-- <template #cell(Aksi)="data"> -->
              <!-- <div style="min-width: 100px"> -->
              <!-- <div>
                <b-button
                  v-if="can('detail/pengelolaan-anggaran/summary')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-b-popover.hover.bottom="'Detail'"
                  size="sm"
                  class="btn-icon float-sm-left"
                  @click="gotoDetailPage(data.item.group_key, data.item.type)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button> -->
                <!-- <b-button
                  v-if="can('edit/pengelolaan-anggaran/'+data.item.type)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  v-b-popover.hover.bottom="'Ubah'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  @click="gotoEditPage(data.item.id, data.item.type)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-if="can('delete/pengelolaan-anggaran/'+data.item.type)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  v-b-popover.hover.bottom="'Hapus'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  @click="confirmDelete(data.item.id, data.item.type)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button> -->
              <!-- </div>
            </template> -->
          </b-table>
          <span v-if="totalRows == 1" style="margin: 1rem"></span>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  @change="changePerPage()"
                  size="sm"
                  inline
                  :options="pageOptions"
              />
            </b-form-group>

            <div>
              Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
            </div>
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col v-else>
        <b-card>
          <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                  Tidak Memiliki Akses
              </h2>
              <p class="mb-2">
                  Anda tidak memiliki akses pada halaman ini
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
  </b-row>
</template>

<style>
  .width160px {
    width: 160px !important;
    background-color: "red !important";
  }
  .vs__dropdown-toggle, .vs__selected {
    max-width: none !important;
  }
  </style>

<script>
  import {
    BRow,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCol,
    BModal,
    VBModal,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    VBPopover,
    BFormCheckbox,
    // BFormDatepicker,
  } from 'bootstrap-vue';
  import vSelect from "vue-select";
  import BCardCode from "@core/components/b-card-code";
  import axios from "@axios";
  import Ripple from "vue-ripple-directive";
  import DatePicker from "vue2-datepicker";
  import moment from 'moment'
  import "vue2-datepicker/index.css";

  export default {
    name: "SummaryList",
    components: {
      BRow,
      BCol,
      BCard,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      vSelect,
      BModal,
      BCardCode,
      BForm,
      BFormRadioGroup,
      BFormTextarea,
      BFormCheckbox,
      DatePicker,
    },
    directives: {
      Ripple,
      "b-popover": VBPopover
    },
    data() {
      const currentYear = new Date().getFullYear();
      const startOfYear = new Date(currentYear, 0, 1); // 1 Januari
      // const startOfYear = new Date();
      const endOfYear = new Date(currentYear, 11, 31); // 31 Desember
      return  {
        config: {
          api: "/budgets",
          subdir: '/misc/sub-direktorat',
          regional: '/misc/regionals',
        },
        search: '',
        dateRange: [startOfYear, endOfYear],
        selectedRange: "",
        startDate: '',
        endDate: '',
        selectedType: {
          text: "Pilih Semua Type",
          value: "0"
        },
        selectedTypeOptions: [
          {text: "Pilih Semua Type", value: "0"},
          {text: "Pusat", value: "pusat"},
          {text: "Regional", value: "regional"},
        ],
        selectedSubdir: {
          text: "Pilihan Semua Sub Direktorat",
          value: "0"
        },
        selectedSubdirOptions: [],
        selectedRegional: {
          text: "Pilihan Semua Regional",
          value: "0"
        },
        selectedRegionalOptions: [],
        perPage: 10,
        currentPage: 1,
        totalRows: 10,
        from: 1,
        to: 10,
        sortBy: 'id',
        pageOptions: [1, 10, 25, 50, 100],
        fields: [
          "no",
          { key: "anggaran", label: "Total" },
          { key: "realisasi", label: "Realisasi" },
          { key: "sisa", label: "Sisa Anggaran" },
          { key: "name_subdirektorat", label: "Sub Direktorat" },
          { key: "name_regional", label: "Regional" },
          // { key: "start_date", label: "Periode Awal", thStyle: 'max-width:300px' },
          // { key: "end_date", label: "Periode Akhir", thStyle: 'max-width:300px' },
          { key: 'type', label: 'Type Anggaran'},
          // { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1'}
        ],
        items: [],
        isRegionalDisabled: true,
        isSubdirDisabled: true
      }
    },
    watch: {
      search: function () {
        this.$refs.tableFe.refresh()
      },
      selectedType: function () {
        this.$refs.tableFe.refresh()
      },
      selectedSubdir: function () {
        this.$refs.tableFe.refresh()
      },
      selectedRegional: function () {
        this.$refs.tableFe.refresh()
      },
    },
    mounted() {
      let _ = this;
      _.getSubdirList();
      _.getRegionalList();

      if (_.$route.params) {
        if (_.$route.params.event) {
          _.makeToast(
            _.$route.params.event,
            _.$route.params.title,
            _.$route.params.text
          );
        }
      }
    },
    methods: {
      gotoDetailPage(id, types) {
        let _ = this;
        _.$router.push("/pengelolaan-anggaran/summary/"+ types +"/" + id + "/detail");
      },
      gotoEditPage(id, types) {
        let _ = this;
        _.$router.push("/pengelolaan-anggaran/"+ types +"/" + id + "/edit");
      },
      getSubdirList() {
        axios.get(this.config.subdir).then(response => {
          // this.selectedSubdirOptions = response.data.data
          var header = [{
            text: "Pilihan Semua Sub Direktorat",
            value: "0"
          }];
          var options = response.data.data.map(item => ({
            // text: item.namabagian_child.length > 30 ? item.namabagian_child.substr(0, 29)+"..." : item.namabagian_child,
            text: item.namabagian_child,
            value: item.kodebagian_child
          }));
          // console.log(options);

          this.selectedSubdirOptions = [...header, ...options];
          // console.log(this.selectedSubdirOptions);
          
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
      },
      getRegionalList() {
        axios.get(this.config.regional).then(response => {
          // this.regionalLists = response.data.data.data
          // this.selectedRegionalOptions = response.data.data.data.map(item => ({
          //   text: item.nmwilayah.length > 40 ? item.nmwilayah.substr(0, 40)+"..." : item.nmwilayah,
          //   value: item.idwilayah
          // }));
          var header = [{
            text: "Pilihan Semua Regional",
            value: "0"
          }];
          var options = response.data.data.data.map(item => ({
            text: item.nmwilayah,
            value: item.idwilayah
          }));

          this.selectedRegionalOptions = [...header, ...options];
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
      },
      confirmDelete(id, types) {
        this.$swal({
          title: 'Apakah Anda Yakin',
          text: 'untuk menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(`${this.config.api}/${id}`)
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: 'Berhasil Dihapus',
                  text: response.data.data.messages,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$refs.tableFe.refresh()
              })
              .catch(error => {
                var message = "";
                if(typeof error.response.data.message === 'object'){
                  message = error.response.data.message.errors[0].msg
                } else {
                  message = error.response.data.message
                }
                this.$swal({
                  icon: 'Danger',
                  title: 'Terjadi kesalahan!',
                  text: message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
      },
      handleSearch() {
        console.log("refresh");
        this.$refs.tableFe.refresh()
      },
      getItems(ctx, callback) {
        const _ = this
  
        let endpoint = `${_.config.api}/summary?per_page=${_.perPage}&page=${_.currentPage}`
        if (_.search) {
          endpoint += `&key=${_.search}`
        }

        if(_.selectedType) {
          endpoint +=  `&type=${_.selectedType.value}`
        }

        if(_.selectedSubdir) {
          endpoint +=  `&subdir=${_.selectedSubdir.value}`
        }

        if(_.selectedRegional) {
          endpoint +=  `&regional=${_.selectedRegional.value}`
        }

        if (_.dateRange.length > 0) {
          // console.log(_.dateRange);

          const currentYear = new Date().getFullYear();
          const stDate = _.dateRange[0] ?? new Date(currentYear, 0, 1);
          const edDate = _.dateRange[1] ?? new Date(currentYear, 11, 31);
          
          var startDate = moment(stDate).format('YYYY-MM-DD');
          var endDate = moment(edDate).format('YYYY-MM-DD');
          // console.log(startDate, endDate);
          // endpoint += `&start_date=${_.dateRange[0]}&end_date=${_.dateRange[1]}`
          endpoint += `&start_date=${startDate}&end_date=${endDate}`
        }
        axios
          .get(endpoint)
          .then(resp => {
            const consume = resp.data.data
            _.perPage = consume.per_page
            _.currentPage = consume.current_page
            _.totalRows = consume.total
            _.from = consume.from ?? 0
            _.to = consume.to ?? 0
            callback(consume.data || [])
          })
          .catch(error => {
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          })
        return null
      },
      changePerPage() {
        const _ = this
        _.$refs.tableFe.refresh()
      },
      changeCurrentPage(currentPage) {
        let _ = this;
        _.currentPage = currentPage;
      },
      makeToast(variant = null, title = "Title here...", text = "Text here...") {
        this.$bvToast.toast(text, {
          title,
          variant,
          solid: true,
        });
      },
      handleTypeChange(value) {
        console.log("Type Anggaran berubah:", value); // Debugging
        if (this.selectedType.value === "pusat") {
          this.isRegionalDisabled = true;
          this.isSubdirDisabled = false;
          this.selectedRegional = { text: "Pilih Semua Regional", value: "0" }; // Reset
          console.log("Mode Pusat -> Regional Disabled, Subdir Enabled"); // Debugging
        } else if (this.selectedType.value === "regional") {
          this.isRegionalDisabled = false;
          this.isSubdirDisabled = true;
          this.selectedSubdir = { text: "Pilih Semua Sub Direktorat", value: "0" }; // Reset
          console.log("Mode Regional -> Subdir Disabled, Regional Enabled"); // Debugging
        } else {
          this.isRegionalDisabled = false;
          this.isSubdirDisabled = false;
          console.log("Mode Default -> Semua Enabled"); // Debugging
        }
      }
    },
  }

</script>